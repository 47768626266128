
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { getCurrentUser } from "@/utils/index"
import { getPrimeMonitoring, getPrimeOneYear } from "@/api/dashboard"
import ECP_EDP_DashboardNavigator from "@/components/cards/ECP_EDP_DashboardNavigator.vue"

export default defineComponent({
  name: "primemonitoring",
  components: {
    ECP_EDP_DashboardNavigator
  },
  data() {
    return { 
      user: {},
      pm: {},
      nextMonth: "",
      currentRank: "",
      loop_topLeg_totalPoints: "",
      loop_super_totalpoints: "",
      loop_otherLeg_perc: "",
      primeMonitoringTreeBuffer: "",
      isLoading:true,
       pt : "",
       rankPrimeOneYear : {}
    }
  },
  mounted(){
      this.user = getCurrentUser();
      this.getPT();
      this.getPrimeMonitoring();
      this.getNextMonth();
      this.getOneYearPrime();
  },
  methods : {
    async getPrimeMonitoring(){
      this.isLoading = true;
      const response = await getPrimeMonitoring();
      this.pm = response.data;
      this.currentRank = response.data.currentRank;

      this.loop_topLeg_totalPoints = response.data.loop_topLeg_totalPoints;
      this.loop_super_totalpoints = response.data.loop_super_totalpoints;
      this.loop_otherLeg_perc = response.data.loop_otherLeg_perc;
      this.primeMonitoringTreeBuffer = response.data.primeMonitoringTreeBuffer;
      this.isLoading = false;
    },

    async getOneYearPrime(){
      this.isLoading = true;
      const response = await getPrimeOneYear();
      this.rankPrimeOneYear = response.data.rankPrimeOneYear;
      this.isLoading = false;
    },

    async getNextMonth(){
      const monthNames = ["January", "February", "March", "April", "May", "June",
		  "July", "August", "September", "October", "November", "December"
		];
      let nextMonth = monthNames[(new Date().getMonth()+1)%12];
      this.nextMonth = nextMonth;
    },
    async getPT(){
       this.pt = String(localStorage.getItem("pt"));
    },
  },
  setup() {
    
  }
});
